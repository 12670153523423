<template>
	<div class="flex-column full-height bg-gray-light position-relative">
		<div class="pa-10 bg-white mb-10">
			<DatePicker :date="item_search.sDate" @click="setDate($event)" ></DatePicker>
		</div>
		<div class="bg-white full-height overflow-y-auto pb-50">
			<table
				v-if="items.length > 0"
				class="table table-even"
			>
				<thead>
				<tr>
					<th>
						<input
							v-model="is_all"
							type="checkbox"
						/>
					</th>
					<th>가맹점</th>
					<th>상점</th>

					<th>조회 내역</th>
					<th>정산 등록</th>
				</tr>
				</thead>
				<tbody>

				<tr
					v-for="(item, index) in items"
					:key="'item_' + index"
					class=""
				>
					<td>
						<input
							v-model="item.is_all"
							type="checkbox"
							:ref="'checkbox_' + index"
						/>
					</td>
					<td class="word-break">{{ item.seller_id }}</td>
					<td>{{ item.shop_name }}</td>

					<td
						v-if="item.settle"
					>

						정산금액: {{ item.settle.amount | makeComma }}
						<br/>
						입금금액: {{ item.settle.deposit | makeComma}}
						<br/>
						송금금액: {{ item.settle.transfer | makeComma}}
					</td>
					<td
						v-else
					>
						-
					</td>
					<td>

						<template
							v-if="item.settle || item.income_amount"
						>
							조회 금액: {{ item.income_amount | makeComma}}
							<br/>
							차감 금액: <input v-if="is_mode" v-model="item.minus_amount" type="number" :rules="$rules.max(item, 'minus_amount', 9)" class="box size-px-12 text-center bg-white" /> <template v-else>{{ item.minus_amount | makeComma }}</template>
							<br/>
							정산 금액: {{ item.amount | makeComma}}
						</template>
						<template
							v-else
						>
							-
						</template>
					</td>
				</tr>
				</tbody>
			</table>
			<Empty
				v-else
			></Empty>

			<div
				v-if="user.agency_type == 'distributor'"
				class="position-absolute flex-column"
				style="right: 20px; bottom: 10px; z-index: 1"
			>
				<button
					v-if="is_delete"
					@click="onDelete"
				><v-icon large class="color-red ">mdi-delete-circle</v-icon></button>
				<button
					v-if="is_deposit"
					@click="onDeposit"
					class="mt-5"
				><v-icon large class="color-blue ">mdi-play-circle-outline</v-icon></button>
			</div>

			<div
				v-if="is_mode"
				class="position-absolute flex-column"
				style="right: 20px; bottom: 60px; z-index: 1"
			>
				<button
					@click="is_mode = false"
				><v-icon large class="color-orange  ">mdi-refresh</v-icon></button>
			</div>
		</div>

		<div class="mt-auto top-line">

			<button
				v-if="is_mode"
				@click="onSettlement"
				class="btn btn-primary"
				:disabled="!list_all.length"
			>선택 정산 등록</button>
			<button
				v-else
				@click="getSettlementSearch"
				class="btn btn-primary"
				:disabled="!list_all.length"
			>{{ now }} / {{ list_all.length }} 선택 정산 조회</button>
		</div>

		<PopupConfirm
			v-if="is_on_deposit"

			@cancel="is_on_deposit = false"
			@click="onPin"
		>
			<template
				v-slot:title
			>정산 지급</template>
			<template
				v-slot:main-txt
			>가맹점 정산금액을 지급하시겠습니까?</template>
		</PopupConfirm>

		<PopupConfirm
			v-if="is_on_delete"

			@cancel="is_on_delete = false"
			@click="postSettlementDelete"
		>
			<template
				v-slot:title
			>정산 취소</template>
			<template
				v-slot:main-txt
			>가맹점 정산내역을 취소하시겠습니까?</template>
		</PopupConfirm>

		<PopupConfirm
			v-if="is_on_settlement"

			@cancel="is_on_settlement = false"
			@click="postSettlement"
		>
			<template
				v-slot:title
			>정산 등록</template>
			<template
				v-slot:main-txt
			>외부 정산 조회 데이터를 등록하시겠습니까?</template>
		</PopupConfirm>
	</div>
</template>

<script>
import Empty from "../Layout/Empty";
import DatePicker from "../../components/DatePicker";
import PopupConfirm from "../Layout/PopupConfirm";
export default {
	name: 'CalculateApi'
	, components: {PopupConfirm, DatePicker, Empty}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '외부 정산 조회'
				, top: false
				, title: true
				, bottom: false
			}
			, items: []
			, item_search: {
				sDate: this.$route.params.date ? this.$route.params.date : this.$date.getToday('-')
			}
			, is_on_deposit: false
			, is_on_delete: false
			, is_settlement: false
			, is_on_download: false
			, is_on_excel: false
			, is_all: false
			, is_mode: false
			, is_on_settlement: false
			, max: 0
			, now: 0
		}
	}
	, computed: {
		is_deposit: function(){

			let i = 0
			this.items.filter ( (item) => {
				if((item.is_settlement && item.is_settlement != '1') || (item.is_deposit && item.is_deposit != '0')){
					i++
				}
			})
			return this.is_settlement && this.items.length > 0 && i == 0 ? true : false
		}
		, is_delete: function(){
			let i = 0

			this.items.filter ( (item) => {
				if(item.is_deposit && item.is_deposit != '0'){
					i++
				}
			})

			return this.is_settlement && this.items.length > 0 && i == 0 ? true : false
		}
		, list_all: function(){
			let t = []
			this.items.filter((item) => {
				if(item.is_all){
					t.push(item.shop_name)
				}
			})
			return t
		}
		, list_settlement: function(){

			let t = []
			this.items.filter((item) => {
				if(item.is_all){
					t.push({
						agency_id: item.agency_id
						, agency_upper_id: item.agency_upper_id
						, amount: item.settle?.amount
						, minus: item.minus_amount
						, shop_uid: item.shop_uid
						, virtual_uid: item.virtual_uid
					})
				}
			})
			return t
		}
	}
	, methods: {
		getData: async function(){
			this.$bus.$emit('on', true)

			try{
				const result = await this.$Axios({
					method: 'get'
					,url: 'member/getOutSettlementList'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toExcel: function(){
			this.$bus.$emit('to', { name: 'CalculateItem', params: { date: this.item_search.date}})
		}
		, toDownload: function(){

		}
		, setDate: function(date){
			this.item_search.sDate = date
			this.getData()
		}
		, onDeposit: function(){
			this.is_on_deposit = true
		}
		, onExcel: function(){
			this.is_on_excel = true
		}
		, onDownload: function(){
			this.is_on_download = true
		}
		, onPin: function(){
			this.$bus.$off('pinCallback')
			this.$bus.$emit('onPin', { type: 'check'})
			this.$bus.$on('pinCallback', (call) => {
				this.postPinCheck(call)
			})
		}

		, postPinCheck: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: this.$api_url.api_path.post_pin_check
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
					}
				})
				if(result.success){
					await this.postDeposit()
					this.$bus.$emit('offPin')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.$bus.$off('pinCallback')
			}
		}
		, postDeposit: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$Axios({
					method: 'post'
					,url: 'member/postDeposit'
					,data: {
						date: this.item_search.date
					}
				})

				if(result.success){
					this.is_on_deposit = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onDelete: function(){
			this.is_on_delete = true
		}
		, postSettlementDelete: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$Axios({
					method: 'post'
					,url: 'member/postSettlementDelete'
					,data: {
						date: this.item_search.date
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.is_on_delete = false
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getSettlementSearch: async function(){
			this.max = this.list_all.length

			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'management/getSettlementSearch'
					,data: {
						search_list: JSON.stringify([this.list_all[this.now]])
						, sDate: this.item_search.sDate
						, eDate: this.item_search.sDate
					}
				})
				if(result.success){
					result.data.filter( (settlement) => {
						this.items.filter( (item) => {
							if(item.shop_name == settlement.shop_name){
								this.$set(item, 'settle', settlement?.data?.settle[0]?.settle)
								this.$refs['checkbox_' + this.now][0].focus()
							}
						})
					})

					this.now++
					if(this.now == this.max){
						this.is_mode = true
						this.now = 0
					}else{
						await this.getSettlementSearch()
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)

				this.is_mode = false
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onSettlement: function(){
			this.is_on_settlement = true
		}
		, postSettlement: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					,url: 'member/postOutSettlement'
					,data: {
						search_list: JSON.stringify(this.list_settlement)
						, sDate: this.item_search.sDate
					}
				})
				if(result.success){
					this.is_mode = false
					this.is_on_settlement = false
					if(result.data.length > 0){
						result.data.filter( (item) => {
							this.items.filter( (shop) => {
								if(item.shop_uid == shop.shop_uid){
									shop.income_amount = item.income_amount
									shop.minus_amount = item.minus_amount
									shop.amount = item.amount
								}
							})
						})
					}
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
	, watch: {
		is_all: {
			handler: function(call){

				this.items.filter( (item) => {
					this.$set(item, 'is_all', call)
				})
			}
		}
	}
}
</script>